import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <div id="404" className="px-2 md:px-32 py-10 md:py-20 text-center  text-white">
            <h1 className="text-4xl font-medium text-primary-lighter mb-8">404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>

    </Layout>
)

export default NotFoundPage
